import React, { useContext } from "react"
import { BlogHero } from "../components/Blog-Hero"
import { KenyaPanel } from "../components/panels/Kenya-Panel"
import { AdPanel } from "../components/panels/Ad-Panel"
import { BlogList } from "../components/Blog-List"
import { graphql } from "gatsby"
import { Seo } from "../components/Seo"
import { FeaturedBlog } from "../components/Featured-Blog"
import { BecomeOneOfUs } from "../components/Become-One-Of-Us"
import { FilterButton } from "../components/Filter-Button"
import { siteContext } from "../layout/SiteContextProvider"
import { AdPanelNoSlide } from "../components/panels/Ad-Panel-No-Slide"

const NewsEvents = ({ data }) => {
  const { categoryMenu, setCategoryMenu, setCategoryActive } =
    useContext(siteContext)

  const expiration_normalizer = items => {
    let ads_array = []

    items.forEach(item => {
      if (
        !item.node.frontmatter.expiration_date ||
        item.node.frontmatter.expiration_date == ""
      ) {
        ads_array.push(item)
      } else {
        let expirationDate = new Date(item.node.frontmatter.expiration_date)
        let currentDate = new Date()
        if (expirationDate > currentDate) {
          ads_array.push(item)
        }
      }
    })
    return ads_array
  }

  const non_expired_ads = expiration_normalizer(data.ads.edges)
  return (
    <>
      <Seo
        title="We honor the complexity of every person’s story"
        description="MLIFE Church & Foundation - We are religious and secular. BIPOC and White. LGBTQ+ and not."
      />{" "}
      <>
        <BlogHero />
        <FeaturedBlog blogs={data.featuredBlog} />
        {/* remove comment out! */}
        <FilterButton blogs={data.blogs} />
        <BlogList categoryMenu={categoryMenu} blogs={data.blogs} />
        {non_expired_ads.length > 1 ? (
          <AdPanel light ads={non_expired_ads} />
        ) : (
          <AdPanelNoSlide light ads={non_expired_ads} />
        )}
        {/* <KenyaPanel light /> */}
        <BecomeOneOfUs />
      </>
    </>
  )
}

export const query = graphql`
  query AllBlogArticles {
    featuredBlog: allMarkdownRemark(
      filter: { frontmatter: { featured: { in: [true] } } }
      # filter: { frontmatter: { slug: { regex: "/ /" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            slug
            featured
            date
            author
            categories
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
          }
          html
        }
      }
    }
    blogs: allMarkdownRemark(
      filter: { frontmatter: { featured: { in: [false, true] } } }
      # filter: { frontmatter: { slug: { regex: "/ /" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            slug
            featured
            date
            author
            categories
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
          }
          html
        }
      }
    }
    ads: allMarkdownRemark(
      filter: {
        frontmatter: {
          ad: { eq: true }
          title: { ne: "DUMMY AD (DO NOT DELETE)" }
        }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            blurb
            description
            ad
            modalbuttontext
            modalbuttonlink
            videolink
            title
            slug
            expiration_date
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
            dates {
              displayformat
              seasondates {
                seasonend
                seasonstart
                seasonyearstart
                seasonyearend
              }
              monthdates {
                monthend
                monthstart
                yearend
                yearstart
              }
              specificdates {
                specificenddate(formatString: "MMM DD, YYYY")
                specificstartdate(formatString: "MMM DD, YYYY")
              }
            }
          }
          html
        }
      }
    }
  }
`

export default NewsEvents
